import { AppImages } from '@/app'
import { SEOProps } from '@/components'
import { Place } from '@/types'

const getCoverImage = (place: Place) => {
  return place?.files?.[0]?.file || AppImages.AreaExample
}

const getImageAlt = (place: Place, imageNumber = 1) => `${place?.name} - Photo ${imageNumber}`
const getSEOProps = (place: Place): Partial<SEOProps> => {
  return {
    pathname: `/${place?.slug}`,
    title: place?.name,
    description: place?.description,
    image: getCoverImage(place),
    appendNameToPageTitle: true,
  }
}

export const PlaceUtils = {
  getSEOProps,
  getImageAlt,
  getCoverImage,
}
