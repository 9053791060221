import { api } from '@/app'
import { Place } from '@/types'
import { queryClient } from './queryClient'
import {
  PaginationResponse,
  QueryManager,
  UsePaginationTypes,
} from '@codeleap/common'

const BASE_URL = 'places/'

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Place>>(BASE_URL, {
    params,
  })
  return response.data
}

export async function retrieve(
  slug: Place['slug'],
  params = { original_points: true },
) {
  const response = await api.get<Place>(`${BASE_URL}${slug}/`, {
    params: {
      ...params,
    },
  })
  return response.data
}

export const placesManager = new QueryManager<Place>({
  itemType: {} as Place,
  name: 'places',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
  //@ts-ignore
  retrieveItem: async (slug: Place['slug']) => {
    const response = await retrieve(slug)
    return response || null
  },
})

export type UsePlacesProps = Pick<
  Place,
  'slug' | 'country_slug' | 'state_slug' | 'county_slug' | 'city_slug' | 'name'
> & {
  enabled?: boolean
}

export const usePlaces = (props?: UsePlacesProps) => {
  const { enabled = true, ...filters } = props || {}

  const places = placesManager.use({
    filter: filters,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled,
      },
    },
  })

  return { places }
}

type RetrieveForSSRResponse = {
  props: {
    place?: Place
  }
  status?: number
}
export async function retrieveForSSR(slug: Place['slug']): Promise<RetrieveForSSRResponse> {
  try {
    const place = await retrieve(slug)

    return {
      props: {
        place,
      },
      status: 200,
    }
  } catch (error) {
    return {
      props: null,
      status: 500,
    }
  }
}
