import { Course, Place } from "@/types";

export function getCoursePathname(course: Partial<Course>) {
  const {
    slug,
    country_slug,
    state_slug,
    county_slug,
    city_slug,
    neighborhood_slug,
  } = course;

  let path = `/routes/`;

  if (country_slug) {
    path += `${country_slug}/`;
  }

  if (state_slug) {
    path += `${state_slug}/`;
  }

  if (county_slug) {
    path += `${county_slug}/`;
  }

  if (city_slug) {
    path += `${city_slug}/`;
  }

  if (neighborhood_slug) {
    path += `${neighborhood_slug}/`;
  }

  if (slug) {
    path += `${slug}/`;
  }

  return path;
}

export function getPlacePathname(place: Place) {
  if (!place) return;

  const { country_slug, state_slug, county_slug, city_slug, slug } = place;

  let pathname = "/routes";

  if (country_slug) {
    pathname += `/${country_slug}`;
  }
  if (state_slug) {
    pathname += `/${state_slug}`;
  }
  if (county_slug) {
    pathname += `/${county_slug}`;
  }
  if (city_slug) {
    pathname += `/${city_slug}`;
  }
  if (slug) {
    pathname += `/${slug}`;
  }

  return pathname;
}

export async function fetchAllData(initialUrl: string) {
  let fetchURL = initialUrl;
  let allData = [];
  let page = 1;

  while (fetchURL) {
    console.log(`Fetching ${fetchURL} -> Page: ${page}...`);
    const res = await fetch(fetchURL);
    const response = await res.json();
    page += 1;

    // Combine current page data with allData
    allData = allData.concat(response.results);

    // Set fetchURL to the next page URL or null if there is no next page
    fetchURL = response.next;
  }

  return allData;
}

export function summaryMessage(
  pageName: string,
  createdPages: number,
  failedMessages: string[]
) {
  const title = `${pageName.toLocaleUpperCase()} PAGES SUMMARY`;
  const footer = `╚═════════════════════════${"═".repeat(
    title.length
  )}═════════════════════════╝`;
  const header =
    `╔═════════════════════════${"═".repeat(
      title.length
    )}═════════════════════════╗\n` +
    `║ ${title}${" ".repeat(footer.length - (title.length + 3))}║\n` +
    footer;

  console.log(header);
  console.log(`\n  ${pageName} pages CREATED: ${createdPages}.\n`);
  console.log(`  ${pageName} pages FAILED: ${failedMessages?.length}.\n`);
  failedMessages.forEach((message) => {
    console.log(`- ${message}`);
  });
  console.log(`\n${footer}`);
}
