import React, { useRef } from 'react'
import { AppForms, OSAlert, variantProvider } from '@/app'
import { View, Text, Icon, Button, Select, TextInput, SegmentedControl, PaceCalculatorTable, TimeInput } from '@/components'
import { APIClient } from '@/services'
import { PaceCalculatorCalculateBody, PaceCalculatorCalculateResponse } from '@/types'
import { PropsOf, useForm, useState, useCallback, useBooleanToggle, onUpdate } from '@codeleap/common'
import { PaceCalculatorEmbedModal } from './PaceCalculatorEmbedModal'
import { PaceCalculatorUtils, useIsMobile } from '@/utils'

export type PaceCalculatorProps = PropsOf<typeof View> & {
  onSuccess?: (res: PaceCalculatorCalculateResponse) => void
}

export const PaceCalculator = ({ onSuccess, ...rest }: PaceCalculatorProps) => {
  const form = useForm(AppForms.paceCalculatorForm, {})
  const isMobile = useIsMobile()
  const [response, setResponse] = useState<PaceCalculatorCalculateResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [embedModalVisible, toggleEmbed] = useBooleanToggle(false)
  const resultsRef = useRef<HTMLDivElement>(null)

  const handleSubmit = async (extraValues = {}) => {
    try {
      setIsLoading(true)
      const res = await APIClient.PaceCalculator.calculate({
        ...form.values,
        target_time: null,
        ...extraValues,
      } as PaceCalculatorCalculateBody)
      form.setFieldValue('target_time', res?.target_time)
      setResponse(res)
      if (isMobile && resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      onSuccess?.(res)
    } catch (error) {
      OSAlert.error({ title: 'Error', body: 'An error occurred while calculating the pace' })
    } finally {
      setIsLoading(false)
    }
  }

  const onTargetTimeChangeDebounced = useCallback((v: string) => {
    handleSubmit({ target_time: v })
  }, [response])

  onUpdate(() => {
    if (form.isValid) handleSubmit()
  }, [
    form.values.distance_unit,
  ])

  return (
    <View css={styles.card} {...rest}>
      <PaceCalculatorEmbedModal visible={embedModalVisible} toggle={toggleEmbed} />

      <View css={styles.leftWrapper}>
        <View css={styles.header}>
          <Icon debugName='PaceCalculator:Icon' name='calculator' variants={['marginBottom:3']} />
          <Text
            text='Pace Calculator'
            variants={['h1']}
            responsiveVariants={{
              mobile: ['h3'],
            }}
            component='h1'
          />
          <Text text='Estimate your finishing time' variants={['neutral-8']} component='h3' />
        </View>

        <Select {...form.register('current_distance')} variants={['fullWidth']} />

        <TimeInput
          wrapperProps={{
            css: { marginRight: 'auto' },
          }}
          {...form.register('current_time')}
          ref={null}
        />

        <Select {...form.register('target_distance')} variants={['fullWidth']} />

        <Button
          variants={['large']}
          loading={isLoading}
          onPress={handleSubmit}
          disabled={!form.isValid}
          css={styles.button}
          debugName='PaceCalculator:Calculate'
          text='Calculate'
        />
      </View>

      <View css={styles.rightWrapper} ref={resultsRef}>
        <Text
          text='Results'
          variants={['h2', 'neutral-9', 'marginBottom:3', 'textCenter']}
          responsiveVariants={{
            mobile: ['h4'],
          }}
          component='h2'
        />

        <View variants={['gap:2', 'justifySpaceBetween', 'alignEnd']}>
          <TimeInput
            {...form.register('target_time')}
            disabled={!response}
            abbreviate={isMobile}
            variants={isMobile ? ['tiny'] : ['highlighted', 'small']}
            onChangeDebounced={onTargetTimeChangeDebounced}
            ref={null}
          />

          <TextInput
            debugName='Average target pace'
            label={'Average target pace'}
            value={response?.target_pace || '-'}
            variants={['fullWidth', 'terciary', 'textCentered', 'highlighted']}
            readOnly
            styles={{ wrapper: { maxWidth: 260 }}}
          />
        </View>

        <Text text='Split times' component='h3' variants={['p3', 'neutral-8', 'marginBottom:1']} />

        <View variants={['justifySpaceBetween', 'marginBottom:1', 'alignCenter']}>
          <Text text='Distance unit' component='h4' variants={['p1', 'color:neutral9']} />

          <SegmentedControl
            debugName='PaceCalculator:SegmentedControl'
            options={[
              { value: 'km', label: 'Km' },
              { value: 'mi', label: 'Miles' },
            ]}
            value={form.values.distance_unit}
            onValueChange={(v) => {
              form.setFieldValue('distance_unit', v)
            }}
            variants={['small', 'terciary']}
          />
        </View>

        <PaceCalculatorTable splitTimes={response?.split_times} total={response?.target_time} />

        <View css={styles.footer}>
          <Button
            disabled={!response}
            onPress={() => { PaceCalculatorUtils.generateBracelet(response) }}
            debugName='PaceCalculator:Export Result'
            variants={['secondary', 'large', 'flex']}
            text='Export Results' icon='download-circle'
          />

          <Button onPress={toggleEmbed} debugName='PaceCalculator:Embed Calculator' variants={['secondary', 'large', 'flex']} text='Embed Calculator' icon='embed' />
        </View>
      </View>
    </View>

  )
}

const BREAKPOINT = 'mobile'

const styles = variantProvider.createComponentStyle((theme) => ({
  card: {
    ...theme.presets.elevated,
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.neutral1,
    ...theme.presets.fullWidth,
    ...theme.spacing.paddingVertical(5),
    minHeight: 670,

    [theme.media.down(BREAKPOINT)]: {
      ...theme.presets.column,
      ...theme.spacing.paddingVertical(0),
      ...theme.spacing.paddingVertical(5),
      ...theme.spacing.paddingHorizontal(2),
    },
  },
  header: {
    ...theme.presets.column,
    ...theme.presets.alignCenter,
    ...theme.presets.fullWidth,
    marginBottom: theme.spacing.value(5),
    textAlign: 'center',
  },
  leftWrapper: {
    flex: 1,
    ...theme.presets.column,
    ...theme.presets.alignCenter,
    ...theme.presets.fullWidth,
    ...theme.spacing.paddingHorizontal(5),

    [theme.media.down(BREAKPOINT)]: {
      ...theme.spacing.paddingHorizontal(0),
      ...theme.spacing.paddingBottom(5),
    },
  },
  rightWrapper: {
    flex: 1,
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.paddingHorizontal(5),
    borderLeft: `1px solid ${theme.colors.neutral3}`,

    [theme.media.down(BREAKPOINT)]: {
      borderLeft: 'none',
      borderTop: `1px solid ${theme.colors.neutral3}`,
      ...theme.spacing.paddingHorizontal(0),
      ...theme.spacing.paddingTop(5),
    },
  },
  footer: {
    marginTop: theme.spacing.value(3),
    gap: theme.spacing.value(3),

    [theme.media.down(BREAKPOINT)]: {
      ...theme.presets.column,
      gap: theme.spacing.value(2),
    },
  },
  button: {
    ...theme.presets.fullWidth,
    marginTop: theme.spacing.value(5),
    maxWidth: 320,
  },
}), true)
