import { Course, CourseFilters } from '@/types'
import { navigate } from './index'
import { navigate as navigateGatsby } from 'gatsby'
import { getCoursePathname } from 'gatsby-utils'

export function navigateToExplore(
  filters?: CourseFilters & { search?: string },
) {
  navigate('Explore', {
    params: filters as any,
  })
}

export function navigateToDetails(
  course: Pick<Course, 'slug' | 'id' | 'country_slug' | 'state_slug' | 'county_slug' | 'city_slug' | 'neighborhood_slug'>,
) {

  const coursePath = getCoursePathname(course)
  navigateGatsby(coursePath)
}

export { getCoursePathname }
