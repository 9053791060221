import { convertParamsToRange } from '@/utils'

type CourseFiltersModalFilters = string

interface Params {
  [key: string]: any
}

export const getFilterOptions = (params) => {

  const filterOptions = [{
    label: 'Paved',
    key: 'paved',
    value: convertParamsToRange(params, 'paved'),
    defaultValue: [0, 100],
    min: 0,
    max: 100,
    suffix: '%',
  },
  {
    label: 'Hilly',
    key: 'hilly',
    value: convertParamsToRange(params, 'hilly'),
    min: 0,
    max: 5,
    defaultValue: [0, 5],
  },
  {
    label: 'Elevation',
    key: 'elevation',
    value: convertParamsToRange(params, 'elevation'),
    min: 0,
    max: 100,
    defaultValue: [0, 100],
    suffix: 'm',
    unlimited: true,
  },
  {
    label: 'Course distance',
    key: 'distance',
    value: convertParamsToRange(params, 'distance'),
    min: 0,
    max: 50,
    defaultValue: [0, 50],
    suffix: 'km',
    unlimited: true,
  },
  ]

  return filterOptions

}

export const clearAllFilters = (
  filterKeys: CourseFiltersModalFilters[],
  setParams: React.Dispatch<React.SetStateAction<Params>>,
) => {
  setParams(state => {
    filterKeys.forEach(key => {
      state[`min_${key}`] = null
      state[`max_${key}`] = null
    })
    return { ...state }
  })
}

export const clearFilters = (
  key: CourseFiltersModalFilters,
  setParams: React.Dispatch<React.SetStateAction<Params>>,
) => {
  setParams(state => {
    state[`min_${key}`] = null
    state[`max_${key}`] = null
    return { ...state }
  })
}
