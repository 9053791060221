import { View, Text, Link } from '@/components'
import { RoutePath } from '@/utils'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
}

type NavigateLink = {
  text: string
  route?: RoutePath
  to?: string
}

const links: Record<string, NavigateLink[]> = {
  pages: [
    { text: 'Home', route: 'Home' },
    { text: 'Explore', route: 'Routes.Explore' },
    { text: 'Areas', route: 'Area' },
    { text: 'Terms & Policy Privacy', route: 'Terms' },
  ],
  others: [
    { text: 'About', route: 'About' },
  ],
}

function Column({ links, title = null, variantStyles }) {
  const renderLink = (link, index) => (
    <View component='li'>
      <Link
        key={index + 'footer-link'}
        route={link?.route}
        to={link?.to}
        openNewTab={!!link?.to}
        text={link?.text}
        css={variantStyles.navigateLink}
      />
    </View>
  )

  return (
    <View css={variantStyles.navigateColumn} component='ul' >
      {!!title && <Text css={variantStyles.navigateTitle} text={title} />}
      {links.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.navigateWrapper} component='nav'>
      <Column
        variantStyles={variantStyles}
        links={links?.pages}
      />
    </View>
  )
}
