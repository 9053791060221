import { ActivityIndicator, CourseCardProps, View, CourseCard, Grid } from '@/components'
import { Course } from '@/types'
import { PropsOf } from '@codeleap/common'
import { useCallback, useEffect, useRef } from 'react'
import { Navigation, onScrollToComponentBottom } from '@/utils'
import { EmptyPlaceholder } from '@codeleap/web'
import { Theme } from '@/app'

type GridProps = Omit<PropsOf<typeof Grid>, 'data' | 'style' | 'placeholder'>

export type CourseGridProps = Partial<GridProps> & {
  courses: Course[]
  cardVariants?: CourseCardProps['variants']
  onPress?: (Course: Course) => void
  style?: PropsOf<typeof View>
}

export const CourseGrid = ({
  courses,
  cardVariants,
  onPress,
  numColumns = 2,
  rowItemsSpacing = Theme.spacing.value(2),
  columnItemsSpacing = Theme.spacing.value(2),
  style,
  ...rest }: CourseGridProps) => {

  const gridRef = useRef(null)

  const onEndReached = () => {
    if (!rest.hasNextPage || rest?.isFetchingNextPage) return
    rest?.fetchNextPage()
  }

  useEffect(() => {
    const handleScroll = () => {
      onScrollToComponentBottom(gridRef?.current, onEndReached)
    }
    window.addEventListener('scroll', handleScroll)

    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [rest?.fetchNextPage, rest?.hasNextPage, rest?.isFetchingNextPage])

  const renderItem = useCallback(({ item }) => {

    return (
      <CourseCard
        course={item}
        variants={['wide', 'fullWidth', 'grid:wide']}
        onPress={() => Navigation.Course.navigateToDetails(item)}
      />
    )
  }, [cardVariants, onPress])

  if (rest.isLoading) {
    return (
      <View variants={['justifyCenter', 'flex', 'alignCenter']}>
        <ActivityIndicator debugName='CourseList:Loading' />
      </View>)
  }

  if (!courses?.length) {
    return (
      <EmptyPlaceholder
        title='No Courses'
        description='There are no Courses on this location'
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  return (
    <View ref={gridRef}>
      <Grid
        data={courses as unknown as string[]}
        debugName='CourseGrid'
        numColumns={numColumns}
        rowItemsSpacing={rowItemsSpacing}
        columnItemsSpacing={columnItemsSpacing}
        renderItem={renderItem}
        isLoading={rest?.isLoading}
        ListLoadingIndicatorComponent={() => (
          <ActivityIndicator debugName='Course List Loader' variants={['fullWidth']} />
        )}
        isFetchingNextPage={rest?.isFetchingNextPage}
      />
    </View>
  )

}

