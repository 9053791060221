import { CourseGrid, Page, Text, View } from '@/components'
import { APIClient } from '@/services'
import { CourseUtils, useFlatlistProps } from '@/utils'
import { useBreakpointMatch } from '@codeleap/web'

export const CoursesPage = () => {
  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 3,
    desktopHuge: 4,
  })
  const { courses } = APIClient.Courses.useCourses({ limit: numColumns * 4 })
  const listProps = useFlatlistProps(courses, {
    noMoreItemsText: '',
  })

  return (
    <Page
      SEOProps={CourseUtils.getSEOAllCourses()}
      variants={['fullContentViewport']}
      contentProps={{ variants: ['paddingVertical:5'] as any }}
    >
      <View variants={['flex', 'column', 'fullHeight']}>
        <Text text='All routes' variants={['h1', 'marginBottom:5']} component='h1' />
        <CourseGrid
          debugName='Routes grid'
          courses={courses?.items}
          numColumns={numColumns}
          {...listProps}
        />
      </View>
    </Page>
  )
}

